import {
  CardLabel,
  CardLabelDesc,
  Dropdown,
  FormStep,
  UploadFile,
} from "@digit-ui-react-components";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { stringReplaceAll } from "../utils";
import Timeline from "../components/TLTimeline";
import UploadFileDigiLocker from "../utils/UploadFile";

const Proof = ({ t, config, onSelect, userType, formData }) => {
  //let index = window.location.href.charAt(window.location.href.length - 1);
  const [digiLockerUpload, setDigilockerUpload] = useState(false);
  const { pathname: url } = useLocation();
  const isMutation = url.includes("property-mutation");

  let index = window.location.href.split("/").pop();
  const [uploadedFile, setUploadedFile] = useState(
    !isMutation
      ? formData?.address?.documents?.ProofOfAddress?.fileStoreId || null
      : formData?.[config.key]?.fileStoreId
  );
  const [file, setFile] = useState(
    formData?.address?.documents?.ProofOfAddress
  );
  const [error, setError] = useState(null);
  const cityDetails = Digit.ULBService.getCurrentUlb();
  const isUpdateProperty = formData?.isUpdateProperty || false;
  const isEditProperty = formData?.isEditProperty || false;

  const [dropdownValue, setDropdownValue] = useState(
    !isMutation
      ? formData?.address?.documents?.ProofOfAddress?.documentType || null
      : formData?.[config.key]?.documentType
  );
  let dropdownData = [];
  const tenantId = Digit.ULBService.getCurrentTenantId();
  const stateId = Digit.ULBService.getStateId();
  const { data: Documentsob = {} } = Digit.Hooks.pt.usePropertyMDMS(
    stateId,
    "PropertyTax",
    "Documents"
  );
  const docs = Documentsob?.PropertyTax?.Documents;
  const proofOfAddress =
    Array.isArray(docs) &&
    docs.filter((doc) => doc.code.includes("ADDRESSPROOF"));
  if (proofOfAddress.length > 0) {
    dropdownData = proofOfAddress[0]?.dropdownData.filter(
      (doc) => doc?.active == true
    );
    dropdownData.forEach((data) => {
      data.i18nKey = stringReplaceAll(data.code, ".", "_");
    });
  }

  function setTypeOfDropdownValue(dropdownValue) {
    dropdownValue?.digiLockerFetch == true
      ? setDigilockerUpload(true)
      : setDigilockerUpload(false),
      setUploadedFile(null);
    setDropdownValue(dropdownValue);
  }

  const handleSubmit = () => {
    let fileStoreId = uploadedFile;
    let fileDetails = file;
    if (fileDetails) fileDetails.documentType = dropdownValue;
    if (fileDetails) fileDetails.fileStoreId = fileStoreId ? fileStoreId : null;
    let address = !isMutation ? formData?.address : {};
    if (address && address.documents) {
      address.documents["ProofOfAddress"] = fileDetails;
    } else {
      address["documents"] = [];
      address.documents["ProofOfAddress"] = fileDetails;
    }
    if (!isMutation) onSelect(config.key, address, "", index);
    else
      onSelect(
        config.key,
        { documentType: dropdownValue, fileStoreId },
        "",
        index
      );
  };
  const onSkip = () => onSelect();

  function selectfile(e, newFile) {
    if (newFile) {
      setFile(newFile);
    } else {
      setFile(e.target.files[0]);
    }
  }

  useEffect(() => {
    (async () => {
      setError(null);
      if (file) {
        if (file.size >= 2000000) {
          setError(t("PT_MAXIMUM_UPLOAD_SIZE_EXCEEDED"));
        } else {
          try {
            const response = await Digit.UploadServices.Filestorage(
              "property-upload",
              file,
              Digit.ULBService.getStateId()
            );
            if (response?.data?.files?.length > 0) {
              setUploadedFile(response?.data?.files[0]?.fileStoreId);
            } else {
              setError(t("PT_FILE_UPLOAD_ERROR"));
            }
          } catch (err) {}
        }
      }
    })();
  }, [file]);
  const checkMutatePT = window.location.href.includes(
    "citizen/pt/property/property-mutation/"
  ) ? (
    <Timeline currentStep={3} flow="PT_MUTATE" />
  ) : (
    <Timeline currentStep={1} />
  );

  return (
    <React.Fragment>
      {window.location.href.includes("/citizen") ? checkMutatePT : null}
      <FormStep
        config={config}
        onSelect={handleSubmit}
        onSkip={onSkip}
        t={t}
        isDisabled={
          isUpdateProperty || isEditProperty
            ? false
            : !uploadedFile || !dropdownValue || error
        }
        cardStyle={{
          backgroundColor: "#fff",
          boxShadow: "none",
          padding: "0px",
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: "180px",
            borderRadius: "10px",
            border: "1px solid #D3D3D3",
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <CardLabelDesc>{t(`PT_UPLOAD_RESTRICTIONS_TYPES`)}</CardLabelDesc>
          <CardLabelDesc>{t(`PT_UPLOAD_RESTRICTIONS_SIZE`)}</CardLabelDesc>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
            }}
          >
            <div style={{ width: "40%" }}>
              <CardLabel>{`${t("PT_CATEGORY_DOCUMENT_TYPE")}`}</CardLabel>
              <Dropdown
                t={t}
                isMandatory={false}
                option={dropdownData}
                selected={dropdownValue}
                optionKey="i18nKey"
                select={setTypeOfDropdownValue}
                placeholder={t(`PT_MUTATION_SELECT_DOC_LABEL`)}
              />
            </div>
            <div style={{ width: "40%" }}>
              <CardLabel>{`Upload File`}</CardLabel>
              {digiLockerUpload ? (
                <UploadFileDigiLocker
                  id={"pt-doc"}
                  extraStyleName={"propertyCreate"}
                  accept=".jpg,.png,.pdf"
                  onUpload={selectfile}
                  onDelete={() => {
                    setUploadedFile(null);
                  }}
                  message={
                    uploadedFile
                      ? `1 ${t(`PT_ACTION_FILEUPLOADED`)}`
                      : t(`PT_ACTION_NO_FILEUPLOADED`)
                  }
                  error={error}
                />
              ) : (
                <>
                  <UploadFile
                    id={"pt-doc"}
                    extraStyleName={"propertyCreate"}
                    accept=".jpg,.png,.pdf"
                    onUpload={selectfile}
                    onDelete={() => {
                      setUploadedFile(null);
                    }}
                    message={
                      uploadedFile
                        ? `1 ${t(`PT_ACTION_FILEUPLOADED`)}`
                        : t(`PT_ACTION_NO_FILEUPLOADED`)
                    }
                    inputStyles={{
                      backgroundColor: "#F0F0F0",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    textStyles={{ color: "#003DA5" }}
                    error={error}
                    Multistyle={{ padding: "0px" }}
                  />
                  {error ? (
                    <div
                      style={{
                        height: "20px",
                        width: "100%",
                        fontSize: "14px",
                        color: "red",
                        marginTop: "5px",
                      }}
                    >
                      {error}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>

          <div
            style={{ disabled: "true", height: "20px", width: "100%" }}
          ></div>
        </div>
      </FormStep>
    </React.Fragment>
  );
};

export default Proof;

import React, { useState, useEffect, useMemo } from "react";
import { ArrowLeft, Header, Loader } from "@digit-ui-react-components";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import MyBill from "./my-bill";

export const BillList = ({ billsList, currentPath, businessService }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const consumerCodes = billsList.map((bill) => bill.consumerCode);
  // const { mobileNumber } = Digit.UserService.getUser()?.info;

  const searchResult = Digit.Hooks.useApplicationsForBusinessServiceSearch({
    filters: { consumerCodes: consumerCodes.join() },
    businessService,
  });

  /*
  call the relevant business search and find what key is being used as consumerCode in bills it is as follows :-

  FSM -> applicationNo
  PT -> propertyId

  */

  const keyForConsumerCode = searchResult.key;

  const [applicationList, setApplicationList] = useState([]);
  const [getKeyNotesConfig, setConfig] = useState(() =>
    Digit.ComponentRegistryService?.getComponent(
      "getBillDetailsConfigWithBusinessService"
    )
  );
  const billableApplicationsObj = useMemo(() => ({}), []);
  const billsListObj = useMemo(() => ({}), []);

  useEffect(() => {
    if (searchResult.data) searchResult.refetch();
  }, []);

  useEffect(() => {
    if (searchResult.data) {
      const billableApps = searchResult.data.filter((property) =>
        consumerCodes.includes(property[keyForConsumerCode])
      );
      const billableIDs = billableApps.map((e) => e[keyForConsumerCode]);

      billableApps.forEach((app) => {
        billableApplicationsObj[app[keyForConsumerCode]] = app;
      });

      billsList.forEach((bill) => {
        billsListObj[bill.consumerCode] = bill;
      });

      const newBillsList = billableIDs.map((e) => ({
        ...billsListObj[e],
        ...billableApplicationsObj[e],
      }));
      setApplicationList(newBillsList);
    }
  }, [searchResult.data, getKeyNotesConfig]);

  if (searchResult.isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          padding: "25px",
          width: "100%",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div>
            {/* <div className="static-wrapper"> */}
            <Header styles={{ marginBottom: "0px" }}>
              {t("CS_TITLE_MY_BILLS") + ` (${applicationList.length})`}
            </Header>
          </div>
          {businessService === "PT" && (
            <div>
              <Link
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "238px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "10px",
                  border: "1px solid #003DA5",
                  color: "#003DA5",
                  fontSize: "16px",
                }}
                to={"/digit-ui/citizen/pt/property/citizen-search"}
              >
                {t("PT_COMMON_CLICK_HERE")}
              </Link>
            </div>
          )}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {applicationList?.length > 0 &&
                getKeyNotesConfig &&
                applicationList.map((bill, index) => (
                  <div key={index}>
                    <MyBill
                      {...{
                        bill,
                        currentPath,
                        businessService,
                        getKeyNotesConfig,
                      }}
                    />
                  </div>
                ))}
            </div>
            {!applicationList?.length > 0 && (
              <p style={{ paddingLeft: "16px" }}>
                {t("CS_BILLS_TEXT_NO_BILLS_FOUND")}
              </p>
            )}
          </div>
        </div>
        {businessService === "PT" && (
          <div
            style={{
              backgroundColor: "#FFE1E1",
              textAlign: "center",
              height: "62px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
              marginBottom: "10px",
            }}
          >
            <p>
              {t("PT_TEXT_NOT_ABLE_TO_FIND_THE_PROPERTY")}
              {/* <span className="link">
              <Link to="/digit-ui/citizen/pt/property/citizen-search">
                {t("PT_COMMON_CLICK_HERE")}
              </Link>
            </span> */}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

import React from "react";
import ReactDOM from "react-dom";

import { initLibraries } from "@digit-ui-libraries";
import { PGRReducers } from "./packages/modules/pgr/src/Module";
import { PTModule, PTLinks, PTComponents } from "./packages/modules/pt/src/Module";
// import { MCollectModule, MCollectLinks } from "./packages/modules/mcollect/src/Module";
// import { TLModule, TLLinks } from "@upyog/digit-ui-module-tl";
import { initFSMComponents } from "./packages/modules/fsm/src/Module";
import { initPGRComponents } from "./packages/modules/pgr/src/Module";
import { initDSSComponents } from "./packages/modules/dss/src/Module";
import { initHRMSComponents } from "./packages/modules/hrms/src/Module";
import { initReceiptsComponents, ReceiptsModule } from "./packages/modules/receipts/src/Module";
// import { initReportsComponents } from "@upyog/digit-ui-module-reports";
import { initTLComponents } from "./packages/modules/tl/src/Module";
import { PaymentModule, PaymentLinks, paymentConfigs } from "./packages/modules/common/src/Module";
import { HRMSModule } from "./packages/modules/hrms/src/Module";
import { initOBPSComponents } from "./packages/modules/obps/src/Module";
import { initEngagementComponents } from "./packages/modules/engagement/src/Module";
import { initNOCComponents } from "./packages/modules/noc/src/Module";
import { initWSComponents } from "./packages/modules/ws/src/Module";
import { DigitUI } from "./packages/modules/core/src/Module";
import { initCommonPTComponents } from "./packages/modules/commonPt/src/Module";
import { initBillsComponents, BillsModule } from "./packages/modules/bills/src/Module";

// import {initCustomisationComponents} from "./customisations";

// import { PGRModule, PGRLinks } from "@upyog/digit-ui-module-pgr";
// import { Body, TopBar } from "@upyog/digit-ui-react-components";
import "./packages/css/example/index.css"

//import { PTRModule, PTRLinks, PTRComponents } from "@upyog-niua/upyog-ui-module-ptr";


// import * as comps from "@upyog/digit-ui-react-components";

// import { subFormRegistry } from "@upyog/digit-ui-libraries";

import { pgrCustomizations, pgrComponents } from "./pgr";

var Digit = window.Digit || {};

const enabledModules = [
  "PGR",
  "FSM",
  "Payment",
  "PT",
  "QuickPayLinks",
  "DSS",
  // "MCollect",
  "HRMS",
  "TL",
  "Receipts",
  "Reports",
  "OBPS",
  "Engagement",
  "NOC",
  "WS",
  "CommonPT",
  "NDSS",
  "Bills",
  "SW",
  "BillAmendment",
  "FireNoc",
  "Birth",
  "Death"
];

const initTokens = (stateCode) => {
  const userType = window.sessionStorage.getItem("userType") || process.env.REACT_APP_USER_TYPE || "CITIZEN";

  const token = window.localStorage.getItem("token")|| process.env[`REACT_APP_${userType}_TOKEN`];
 
  const citizenInfo = window.localStorage.getItem("Citizen.user-info")
 
  const citizenTenantId = window.localStorage.getItem("Citizen.tenant-id") || stateCode;

  const employeeInfo = window.localStorage.getItem("Employee.user-info");
  const employeeTenantId = window.localStorage.getItem("Employee.tenant-id");

  const userTypeInfo = userType === "CITIZEN" || userType === "QACT" ? "citizen" : "employee";
  window.Digit.SessionStorage.set("user_type", userTypeInfo);
  window.Digit.SessionStorage.set("userType", userTypeInfo);

  if (userType !== "CITIZEN") {
    window.Digit.SessionStorage.set("User", { access_token: token, info: userType !== "CITIZEN" ? JSON.parse(employeeInfo) : citizenInfo });
  } else {
    // if (!window.Digit.SessionStorage.get("User")?.extraRoleInfo) window.Digit.SessionStorage.set("User", { access_token: token, info: citizenInfo });
  }

  window.Digit.SessionStorage.set("Citizen.tenantId", citizenTenantId);

  if (employeeTenantId && employeeTenantId.length) window.Digit.SessionStorage.set("Employee.tenantId", employeeTenantId);
};

const initDigitUI = () => {
  window?.Digit.ComponentRegistryService.setupRegistry({
    ...pgrComponents,
    PaymentModule,
    ...paymentConfigs,
    PaymentLinks,
    PTModule,
    PTLinks,
    ...PTComponents,
    // MCollectLinks,
    // MCollectModule,
    HRMSModule,
    ReceiptsModule,
    BillsModule
    // TLModule,
    // TLLinks,
  });

  initFSMComponents();
  initPGRComponents();
  initDSSComponents();
  // initMCollectComponents();
  initHRMSComponents();
  initTLComponents();
  initReceiptsComponents();
  // initReportsComponents();
  initOBPSComponents();
  initEngagementComponents();
  initNOCComponents();
  initWSComponents();
  initCommonPTComponents();
  initBillsComponents();

  // initCustomisationComponents();

  const moduleReducers = (initData) => ({
    pgr: PGRReducers(initData),
  });

  window.Digit.Customizations = {
    PGR: pgrCustomizations,
    TL: {
      customiseCreateFormData: (formData, licenceObject) => licenceObject,
      customiseRenewalCreateFormData: (formData, licenceObject) => licenceObject,
      customiseSendbackFormData: (formData, licenceObject) => licenceObject,
    },
  };

  const stateCode = window?.globalConfigs?.getConfig("STATE_LEVEL_TENANT_ID") || "pg";
  initTokens(stateCode);

  const registry = window?.Digit.ComponentRegistryService.getRegistry();
  ReactDOM.render(<DigitUI stateCode={stateCode} enabledModules={enabledModules} moduleReducers={moduleReducers} />, document.getElementById("root"));
};

initLibraries().then(() => {
  initDigitUI();
});

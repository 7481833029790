import {
  CardLabel,
  CardLabelError,
  Dropdown,
  FormStep,
  LabelFieldPair,
  RadioOrSelect,
} from "@digit-ui-react-components";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Timeline from "../components/TLTimeline";

const PTSelectAddress = ({
  t,
  config,
  onSelect,
  userType,
  formData,
  setError,
  clearErrors,
  formState,
}) => {
  const allCities = Digit.Hooks.pt.useTenants();
  let tenantId = Digit.ULBService.getCurrentTenantId();
  const { pathname } = useLocation();
  const presentInModifyApplication = pathname.includes("modify");

  let isEditProperty = formData?.isEditProperty || false;
  if (presentInModifyApplication) isEditProperty = true;
  if (formData?.isUpdateProperty) isEditProperty = true;
  const { pincode, city } = formData?.address || "";
  const cities =
    userType === "employee"
      ? allCities.filter((city) => city.code === tenantId)
      : pincode
      ? allCities.filter((city) => city?.pincode?.some((pin) => pin == pincode))
      : allCities;

  const [selectedCity, setSelectedCity] = useState(() => {
    return formData?.address?.city || null;
  });

  const { data: fetchedLocalities } = Digit.Hooks.useBoundaryLocalities(
    selectedCity?.code,
    "revenue",
    {
      enabled: !!selectedCity,
    },
    t
  );

  const [zones, setZones] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [localities, setLocalities] = useState();
  const [selectedLocality, setSelectedLocality] = useState();
  const [selectedZone, setSelectedZone] = useState();


  useEffect(() => {
    if (
      userType === "employee" &&
      presentInModifyApplication &&
      localities?.length
    ) {
      const code = formData?.originalData?.address?.locality?.code;
      const _locality = localities?.filter((e) => e.code === code)[0];
      setValue("locality", _locality);
    }
  }, [localities]);

  useEffect(() => {
    if (cities) {
      if (cities.length === 1) {
        setSelectedCity(cities[0]);
      }
    }
  }, [cities]);

  // useEffect(() => {
  //   if (selectedCity && fetchedLocalities) {
  //     let __localityList = [];

  //     fetchedLocalities.forEach((zone) => {
  //       zone.children.forEach((ward) => {
  //         ward.children.forEach((locality) => {
  //           __localityList.push(locality);
  //         });
  //       });
  //     });

  //     let filteredLocalityList = [];

  //     if (formData?.address?.locality) {
  //       setSelectedLocality(formData.address.locality);
  //     }

  //     if (formData?.address?.pincode) {
  //       filteredLocalityList = __localityList.filter((obj) =>
  //         obj.pincode?.find((item) => item == formData.address.pincode)
  //       );
  //       if (!formData?.address?.locality) setSelectedLocality();
  //     }
  //     setLocalities(() =>
  //       filteredLocalityList.length > 0 ? filteredLocalityList : __localityList
  //     );

  //     if (filteredLocalityList.length === 1) {
  //       setSelectedLocality(filteredLocalityList[0]);
  //       if (userType === "employee") {
  //         onSelect(config.key, {
  //           ...formData[config.key],
  //           locality: filteredLocalityList[0],
  //         });
  //       }
  //     }
  //   }
  // }, [selectedCity, formData?.address?.pincode, fetchedLocalities]);

  useEffect(() => {
    console.log("XXXX")
    console.log(fetchedLocalities,"XXXX@@@")
    if (fetchedLocalities) {
      const __zones = fetchedLocalities;
      const __wards = fetchedLocalities.flatMap((zone) => zone.children);
      console.log(__wards,"XXXX2222")
      setWards(__wards);
      setZones(__zones)
    }
  }, [fetchedLocalities]);

  console.log(wards,"XXXXXWARDS")

  useEffect(() => {
    if (selectedCity && selectedWard) {
      // let __localityList = [];
      // fetchedLocalities.forEach((zone) => {
      //   zone.children.forEach((ward) => {
      //     ward.children.forEach((locality) => {
      //       __localityList.push(locality);
      //     });
      //   });
      // });

      let filteredLocalityList = [];

      if (formData?.address?.locality) {
        setSelectedLocality(formData.address.locality);
      }

      if (formData?.address?.pincode) {
        filteredLocalityList = __localityList.filter((obj) =>
          obj.pincode?.includes(formData.address.pincode)
        );
        if (!formData?.address?.locality) setSelectedLocality(null);
      }

      // Update localities based on filtered results or fallback to all localities
      // setLocalities(
      //   filteredLocalityList.length > 0 ? filteredLocalityList : __localityList
      // );

      if (selectedWard) {
        const wardLocalities = selectedWard.children || [];
        setLocalities(wardLocalities);
      } else {
        setLocalities([]);
      }

      // Automatically select the locality if there's only one option
      if (filteredLocalityList.length === 1) {
        setSelectedLocality(filteredLocalityList[0]);
        if (userType === "employee") {
          onSelect(config.key, {
            ...formData[config.key],
            locality: filteredLocalityList[0],
          });
        }
      }
    }
  }, [selectedCity, selectedWard, formData, userType, config, onSelect]);

  console.log(fetchedLocalities, "fetchedLocalities");
  console.log(localities, "localities");

  function selectCity(city) {
    setSelectedLocality(null);
    setLocalities(null);
    setSelectedCity(city);
  }

  function selectWard(ward) {
    console.log(ward, "selectward");
    // if (formData?.address?.locality) {
    //   formData.address["locality"] = locality;
    // }
    setLocalities(ward.children)
    setSelectedWard(ward);
  }
  function selectZone(zone) {
    // if (formData?.address?.locality) {
    //   formData.address["locality"] = locality;
    // }
    setWards(zone.children)
    setSelectedZone(zone);
  }

  function selectLocality(locality) {
    console.log(locality, "selectLocality");
    if (formData?.address?.locality) {
      formData.address["locality"] = locality;
    }
    setSelectedLocality(locality);
    if (userType === "employee") {
      onSelect(config.key, { ...formData[config.key], locality: locality });
    }
  }

  function onSubmit() { 
    // selectedLocality["rateZone"] = selectedLocality.children[0].code;
    // unset(selectedLocality.children)  
    onSelect(config.key, { city: selectedCity, locality: selectedLocality });
  }

  const {
    control,
    formState: localFormState,
    watch,
    setError: setLocalError,
    clearErrors: clearLocalErrors,
    setValue,
  } = useForm();
  const formValue = watch();
  const { errors } = localFormState;
  const errorStyle = {
    width: "70%",
    marginLeft: "30%",
    fontSize: "12px",
    marginTop: "-21px",
  };

  useEffect(() => {
    if (userType === "employee") {
      let keys = Object.keys(formValue);
      const part = {};
      keys.forEach((key) => (part[key] = formData[config.key]?.[key]));
      if (!_.isEqual(formValue, part))
        onSelect(config.key, { ...formData[config.key], ...formValue });
      for (let key in formValue) {
        if (!formValue[key] && !localFormState?.errors[key]) {
          setLocalError(key, {
            type: `${key.toUpperCase()}_REQUIRED`,
            message: t(`CORE_COMMON_REQUIRED_ERRMSG`),
          });
        } else if (formValue[key] && localFormState.errors[key]) {
          clearLocalErrors([key]);
        }
      }
    }
  }, [formValue]);

  useEffect(() => {
    if (userType === "employee") {
      const errorsPresent = !!Object.keys(localFormState.errors).lengtha;
      if (errorsPresent && !formState.errors?.[config.key])
        setError(config.key, { type: "required" });
      else if (!errorsPresent && formState.errors?.[config.key])
        clearErrors(config.key);
    }
  }, [localFormState]);

 

  if (userType === "employee") {
    return (
      <div>
        <LabelFieldPair>
          <CardLabel className="card-label-smaller">
            {t("MYCITY_CODE_LABEL") + " *"}
          </CardLabel>
          <Controller
            name={"city"}
            defaultValue={cities?.length === 1 ? cities[0] : selectedCity}
            control={control}
            render={(props) => (
              <Dropdown
                className="form-field"
                selected={props.value}
                disable={isEditProperty ? isEditProperty : cities?.length === 1}
                option={cities}
                select={props.onChange}
                optionKey="code"
                onBlur={props.onBlur}
                t={t}
              />
            )}
          />
        </LabelFieldPair>
        <CardLabelError style={errorStyle}>
          {localFormState.touched.city ? errors?.city?.message : ""}
        </CardLabelError>
        <LabelFieldPair>
          <CardLabel className="card-label-smaller">
            {t("PT_LOCALITY_LABEL") + " *"}
          </CardLabel>
          <Controller
            name="locality"
            defaultValue={null}
            control={control}
            render={(props) => (
              <Dropdown
                className="form-field"
                selected={props.value}
                option={localities}
                select={props.onChange}
                onBlur={props.onBlur}
                optionKey="i18nkey"
                t={t}
                disable={isEditProperty ? isEditProperty : false}
              />
            )}
          />
        </LabelFieldPair>
        <CardLabelError style={errorStyle}>
          {localFormState.touched.locality ? errors?.locality?.message : ""}
        </CardLabelError>
      </div>
    );
  }
  return (
    <React.Fragment>
      {window.location.href.includes("/citizen") ? (
        window.location.href.includes(
          "/citizen/pt/property/property-mutation"
        ) ? (
          <Timeline currentStep={1} flow="PT_MUTATE" />
        ) : (
          <Timeline currentStep={1} />
        )
      ) : null}
      <FormStep
        config={config}
        onSelect={onSubmit}
        t={t}
        isDisabled={selectedLocality ? false : true}
        cardStyle={{
          backgroundColor: "#fff",
          boxShadow: "none",
          padding: "0px",
        }}
        inputFieldWrapperStyle={{
          width: "100%",
          height: "auto",
          borderRadius: "10px",
          border: "1px solid #D3D3D3",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <div style={{
            position: "relative",
            width: "100%"
          }}>
            {/* <CardLabel>{`${t("MYCITY_CODE_LABEL")} `}</CardLabel> */}
            {/* <span className={"form-pt-dropdown-only"}>
              <RadioOrSelect
                options={cities.sort((a, b) => a.name.localeCompare(b.name))}
                selectedOption={selectedCity}
                optionKey="i18nkey"
                onSelect={selectCity}
                t={t}
                isPTFlow={true}
                //isDependent={true}
                //labelKey="TENANT_TENANTS"
                disabled={isEditProperty}
                Page="PropertyAddress"
              />
            </span> */}

            {/* <select
              value={selectedWard?.code || ""}
              onChange={(e) => {
                const wardCode = e.target.value;
                const selected = wards.find((ward) => ward.code === wardCode);
                setSelectedWard(selected);
              }}
            >
              <option value="">Select Ward</option>
              {wards.map((ward) => (
                <option key={ward.code} value={ward.code}>
                  {ward.name}
                </option>
              ))}
            </select> */}
            
            <Dropdown
              // dropdownStyle={{ paddingBottom: "20px", width: "348px" }}
              isMandatory={config.isMandatory}
              option={zones.sort((a, b) => a.name.localeCompare(b.name))}
              selected={selectedZone}
              optionKey="i18nkey"
              select={selectZone}
              t={t}
              labelKey="Zone"
            />

            <Dropdown
              // dropdownStyle={{ paddingBottom: "20px", width: "348px" }}
              isMandatory={config.isMandatory}
              option={wards
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((ward) => ({
                  ...ward,
                  i18nkey: `${ward.code}`,
                   
                }))}
              selected={selectedWard}
              optionKey="i18nkey"
              select={selectWard}
              t={t}
              //isDependent={true} 
            /> 
            {/* {selectedCity && localities && (
              <CardLabel>{`${t("PT_LOCALITY_LABEL")} `}</CardLabel>
            )} */}
            {selectedCity && localities && (
              <span className={"form-pt-dropdown-only"}>
                {/* <RadioOrSelect
                  dropdownStyle={{ paddingBottom: "20px", width: "348px" }}
                  isMandatory={config.isMandatory}
                  options={localities.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  )}
                  selectedOption={selectedLocality}
                  optionKey="i18nkey"
                  onSelect={selectLocality}
                  t={t}
                  //isDependent={true}
                  labelKey=""
                  disabled={isEditProperty}
                  Page="PropertyAddress"
                /> */}
                <Dropdown
                  dropdownStyle={{ paddingBottom: "20px", width: "348px" }}
                  isMandatory={config.isMandatory}
                  option={localities
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((locality) => ({
                      ...locality,
                      i18nkey: `${locality.code}`
                    }))}
                  selected={selectedLocality}
                  optionKey="i18nkey"
                  select={selectLocality}
                  t={t}
                  //isDependent={true}
                  labelKey=""
                  disabled={isEditProperty}
                  Page="PropertyAddress"
                />
              </span>
            )}
          </div>
        </div>
      </FormStep>
    </React.Fragment>
  );
};

export default PTSelectAddress;

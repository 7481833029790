import React from "react";
import PropTypes from "prop-types";

import Card from "../atoms/Card";
import CardHeader from "../atoms/CardHeader";
import CardText from "../atoms/CardText";
import SubmitBar from "../atoms/SubmitBar";
import LinkButton from "../atoms/LinkButton";
import CardCaption from "../atoms/CardCaption";
import TextInput from "../atoms/TextInput";

const InputCard = ({
  t,
  children,
  texts = {},
  submit = false,
  inputs = [],
  inputRef,
  onNext,
  onSkip,
  isDisable,
  onAdd,
  isMultipleAllow = false,
  cardStyle,
  inputFieldWrapperStyle,
  submitButtonStyle
}) => {
  const isMobile = window.Digit.Utils.browser.isMobile();
  // TODO: inputs handle
  return (
    <Card style={cardStyle}>
      {texts.headerCaption && (
        <CardCaption>{t(texts.headerCaption)}</CardCaption>
      )}
      {texts?.header && (
        <CardHeader
          styles={{
            FontWeight: "500",
            fontSize: "22px",
            marginBottom: "12px",
            letterSpacing: "2.5px",
          }}
        >
          {t(texts.header)}
        </CardHeader>
      )}
      {inputFieldWrapperStyle ? (
        <div style={inputFieldWrapperStyle}>
          {texts?.cardText && (
            <CardText
              styles={{
                minWidth: "345px",
                fontSize: "15px",
                textAlign: "left",
                lineHeight: "24px",
                letterSpacing: "0.5px",
              }}
            >
              {t(texts.cardText)}
            </CardText>
          )}
          {children}
        </div>
      ) : (
        <>
          {texts?.cardText && (
            <CardText
              styles={{
                minWidth: "345px",
                fontSize: "15px",
                textAlign: "left",
                lineHeight: "24px",
                letterSpacing: "0.5px",
              }}
            >
              {t(texts.cardText)}
            </CardText>
          )}
          {children}
        </>
      )}
      {texts.skipText ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          {texts.submitBarLabel ? (
            <SubmitBar
              disabled={isDisable}
              submit={submit}
              label={t(texts.submitBarLabel)}
              onSubmit={onNext}
              style={submitButtonStyle ? submitButtonStyle :{
                width: "176px",
                height: "45px",
                backgroundColor: "#003DA5",
                borderRadius: "10px",
                color: "#fff",
                fontSize: "12px",
                lineHeight: "15px",
              }}
            />
          ) : null}
          {texts.skipText ? (
            <LinkButton
              label={t(texts.skipText)}
              onClick={onSkip}
              style={{
                width: "172px",
                border: "1px solid #003DA5",
                color: "#003DA5",
                fontSize: "14px",
                textAlign: "center",
                borderRadius: "10px",
                padding: "5px",
                marginTop: "0px",
              }}
            />
          ) : null}
        </div>
      ) : (
        <>
          {texts.submitBarLabel ? (
            <SubmitBar
              disabled={isDisable}
              submit={submit}
              label={t(texts.submitBarLabel)}
              onSubmit={onNext}
              style={submitButtonStyle ? submitButtonStyle : {
                width: "176px",
                height: "45px",
                backgroundColor: "#003DA5",
                borderRadius: "10px",
                color: "#fff",
                fontSize: "12px",
                lineHeight: "15px",
              }}
            />
          ) : null}
          {texts.skipLabel ? (
            <CardText
              style={{
                marginTop: "10px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              {" "}
              {t(texts.skipLabel)}{" "}
            </CardText>
          ) : null}
          {texts.skipText ? (
            <LinkButton
              label={t(texts.skipText)}
              onClick={onSkip}
              style={{
                width: "172px",
                border: "1px solid #003DA5",
                color: "#003DA5",
                fontSize: "14px",
                textAlign: "center",
                borderRadius: "10px",
                padding: "5px",
                marginTop: "0px",
              }}
            />
          ) : null}
          {isMultipleAllow && texts.addMultipleText ? (
            <LinkButton label={t(texts.addMultipleText)} onClick={onAdd} />
          ) : null}
        </>
      )}
    </Card>
  );
};

InputCard.propTypes = {
  text: PropTypes.object,
  submit: PropTypes.bool,
  onNext: PropTypes.func,
  onSkip: PropTypes.func,
  onAdd: PropTypes.func,
  t: PropTypes.func,
};

InputCard.defaultProps = {
  texts: {},
  submit: false,
  onNext: undefined,
  onSkip: undefined,
  onAdd: undefined,
  t: (value) => value,
};

export default InputCard;

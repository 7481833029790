import { Header, Loader } from "@digit-ui-react-components";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PTApplication from "./pt-application";
import { propertyCardBodyStyle } from "../../../utils";
import { Plus } from "lucide-react";

export const PTMyApplications = () => {
  const { t } = useTranslation();
  const tenantId =
    Digit.ULBService.getCitizenCurrentTenant(true) ||
    Digit.ULBService.getCurrentTenantId();
  const user = Digit.UserService.getUser().info;

  let filter = window.location.href.split("/").pop();
  let t1;
  let off;
  if (!isNaN(parseInt(filter))) {
    off = filter;
    t1 = parseInt(filter) + 50;
  } else {
    t1 = 4;
  }
  let filter1 = !isNaN(parseInt(filter))
    ? {
        limit: "50",
        sortOrder: "ASC",
        sortBy: "createdTime",
        offset: off,
        tenantId,
        status: "INWORKFLOW",
      }
    : {
        limit: "4",
        sortOrder: "ASC",
        sortBy: "createdTime",
        offset: "0",
        mobileNumber: user?.mobileNumber,
        tenantId,
        status: "INWORKFLOW",
      };

  const { isLoading, isError, error, data } = Digit.Hooks.pt.usePropertySearch(
    { filters: filter1 },
    { filters: filter1 }
  );

  const { Properties: applicationsList } = data || {};
  let combinedApplicationNumber =
    applicationsList?.length > 0
      ? applicationsList?.map((ob) => ob?.acknowldgementNumber)
      : [];
  let serviceSearchArgs = {
    tenantId: tenantId,
    referenceIds: combinedApplicationNumber,
  };

  const {
    isLoading: serviceloading,
    data: servicedata,
  } = Digit.Hooks.useFeedBackSearch(
    { filters: { serviceSearchArgs } },
    {
      filters: { serviceSearchArgs },
      enabled: combinedApplicationNumber?.length > 0 ? true : false,
      cacheTime: 0,
    }
  );

  function getLabelValue(curservice) {
    let foundValue = servicedata?.Service?.find((ob) =>
      ob?.referenceId?.includes(curservice?.acknowldgementNumber)
    );

    if (foundValue) return t("CS_CF_VIEW");
    else if (!foundValue && curservice?.status?.includes("ACTIVE"))
      return t("CS_CF_RATE_US");
    else return t("CS_CF_TRACK");
  }

  if (isLoading || serviceloading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          padding: "25px",
          width: "100%",
          marginLeft: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div>
            <Header styles={{ marginBottom: "0px" }}>{`${t(
              "CS_TITLE_MY_APPLICATIONS"
            )} ${
              applicationsList ? `(${applicationsList.length})` : ""
            }`}</Header>
          </div>
          <div>
            <Link
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "238px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderRadius: "10px",
                border: "1px solid #003DA5",
                color: "#003DA5",
                fontSize: "16px",
              }}
              to={"/digit-ui/citizen/pt/property/new-application/info"}
            >
              <Plus size={14} />
              {t("CPT_REG_NEW_PROPERTY")}
            </Link>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {applicationsList?.length > 0 &&
                applicationsList.map((application, index) => (
                  <div key={index}>
                    <PTApplication
                      application={application}
                      tenantId={user?.permanentCity}
                      buttonLabel={getLabelValue(application)}
                    />
                  </div>
                ))}
            </div>

            {!applicationsList?.length > 0 && (
              <p style={{ marginLeft: "16px", marginTop: "16px" }}>
                {t("PT_NO_APPLICATION_FOUND_MSG")}
              </p>
            )}

            {applicationsList?.length !== 0 && (
              <div>
                <p style={{ marginTop: "30px" }}>
                  <span className="link">
                    {
                      <Link
                        to={`/digit-ui/citizen/pt/property/my-applications/${t1}`}
                      >
                        {t("PT_LOAD_MORE_MSG")}
                      </Link>
                    }
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div style={{backgroundColor:'#FFE1E1', textAlign:'center', height:'62px', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'40px', marginBottom:'10px'}}>
          <p>
            {t("PT_TEXT_NOT_ABLE_TO_FIND_THE_APPLICATION")}{" "}
          </p>
        </div>
        {/* <p style={{ marginLeft: "16px", marginTop: "16px" }}>
          {t("PT_TEXT_NOT_ABLE_TO_FIND_THE_APPLICATION")}{" "}
          <span className="link" style={{ display: "block" }}>
            <Link to="/digit-ui/citizen/pt/property/new-application/info">
              {t("PT_COMMON_CLICK_HERE_TO_REGISTER_NEW_PROPERTY")}
            </Link>
          </span>
        </p> */}
      </div>
    </React.Fragment>
  );
};

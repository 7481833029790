import React, { useEffect, useState } from "react";
import {
  StandaloneSearchBar,
  Loader,
  CardBasedOptions,
  ComplaintIcon,
  PTIcon,
  CaseIcon,
  DropIcon,
  HomeIcon,
  Calender,
  DocumentIcon,
  HelpIcon,
  WhatsNewCard,
  OBPSIcon,
  WSICon,
} from "@digit-ui-react-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CitizenSideBar } from "../../../components/TopBarSideBar/SideBar/CitizenSideBar";
import StaticCitizenSideBar from "../../../components/TopBarSideBar/SideBar/StaticCitizenSideBar";
import bannerImage from "../../../../../../css/img/upyogi.png"; // Adjust the path as needed
import DashBoardBar from "../../../../../../css/img/DashBoardBar.png";
import MainDashboard from "../../../../../../css/img/MainDashboard.png";
import { X } from "lucide-react";

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [embedUrl, setEmbedUrl] = useState(null);
  const [dashboardBarShow, setDashboardBarShow] = useState(true)
  const tenantId = Digit.ULBService.getCitizenCurrentTenant(true);
  const {
    data: { stateInfo, uiHomePage } = {},
    isLoading,
  } = Digit.Hooks.useStore.getInitData();
  let isMobile = window.Digit.Utils.browser.isMobile();
  if (window.Digit.SessionStorage.get("TL_CREATE_TRADE"))
    window.Digit.SessionStorage.set("TL_CREATE_TRADE", {});

  const conditionsToDisableNotificationCountTrigger = () => {
    if (Digit.UserService?.getUser()?.info?.type === "EMPLOYEE") return false;
    if (!Digit.UserService?.getUser()?.access_token) return false;
    return true;
  };

  const handleDashBoardBarClick = () => {
    setDashboardBarShow(false)
  }

  const selectCity = {
    i18nKey: "TENANT_TENANTS_PG",
    code: "pg",
    name: "State",
    description: "State",
    logoId:
      "https://s3.ap-south-1.amazonaws.com/pg-egov-assets/pg.citya/logo.png",
    imageId: null,
    domainUrl: "www.upyog.niua.org",
    type: "CITY",
    twitterUrl: null,
    facebookUrl: null,
    emailId: "pg.state@gmail.com",
    OfficeTimings: {
      "Mon - Fri": "9.00 AM - 5.00 PM",
    },
    city: {
      name: "State",
      localName: "Demo State",
      districtCode: "0",
      districtName: "State",
      districtTenantCode: "pg",
      regionName: "State",
      ulbGrade: "ST",
      longitude: 75.3412,
      latitude: 31.1471,
      shapeFileLocation: null,
      captcha: null,
      code: "0",
      ddrName: null,
    },
    address: "State Municipal Corporation",
    contactNumber: "0978-7645345",
  };

  const fetchEmbedUrl = async () => {
    try {
      const response = await fetch('https://dashboard.indicsoft.com/admin-city-one-app/api/dashboard');
      const data = await response.json();
      setEmbedUrl(data?.embedUrl); // Set the embed URL in state
    } catch (error) {
      console.error('Error fetching dashboard URL:', error);
    }
  };

  const user = Digit.UserService?.getUser();

  useEffect(() => {
    if (!user || !user.access_token) {
      history.push(`/digit-ui/citizen/login`);
    } else {
      // Proceed with normal setup
      fetchEmbedUrl();
    }
    Digit.LocalizationService.changeLanguage("en_IN", "pg");
    Digit.SessionStorage.set("CITIZEN.COMMON.HOME.CITY", selectCity);
  }, []);

  const {
    data: EventsData,
    isLoading: EventsDataLoading,
  } = Digit.Hooks.useEvents({
    tenantId,
    variant: "whats-new",
    config: {
      enabled: conditionsToDisableNotificationCountTrigger(),
    },
  });

  if (!tenantId) {
    Digit.SessionStorage.get("locale") === null
      ? history.push(`/digit-ui/citizen/login`)
      : history.push(`/digit-ui/citizen/login`);
  }

  const appBannerWebObj = uiHomePage?.appBannerDesktop;
  const appBannerMobObj = uiHomePage?.appBannerMobile;
  const citizenServicesObj = uiHomePage?.citizenServicesCard;
  const infoAndUpdatesObj = uiHomePage?.informationAndUpdatesCard;
  const whatsAppBannerWebObj = uiHomePage?.whatsAppBannerDesktop;
  const whatsAppBannerMobObj = uiHomePage?.whatsAppBannerMobile;
  const whatsNewSectionObj = uiHomePage?.whatsNewSection;

  const handleClickOnWhatsAppBanner = (obj) => {
    window.open(obj?.navigationUrl);
  };

  const allCitizenServicesProps = {
    header: t(citizenServicesObj?.headerLabel),
    sideOption: {
      name: t(citizenServicesObj?.sideOption?.name),
      onClick: () =>
        history.push(citizenServicesObj?.sideOption?.navigationUrl),
    },
    options: [
      {
        name: t(citizenServicesObj?.props?.[0]?.label),
        Icon: <ComplaintIcon />,
        onClick: () =>
          history.push(citizenServicesObj?.props?.[0]?.navigationUrl),
      },
      {
        name: t(citizenServicesObj?.props?.[1]?.label),
        Icon: <PTIcon className="fill-path-primary-main" />,
        onClick: () =>
          history.push(citizenServicesObj?.props?.[1]?.navigationUrl),
      },
      {
        name: t(citizenServicesObj?.props?.[2]?.label),
        Icon: <CaseIcon className="fill-path-primary-main" />,
        onClick: () =>
          history.push(citizenServicesObj?.props?.[2]?.navigationUrl),
      },
      // {
      //     name: t("ACTION_TEST_WATER_AND_SEWERAGE"),
      //     Icon: <DropIcon/>,
      //     onClick: () => history.push("/digit-ui/citizen")
      // },
      {
        name: t(citizenServicesObj?.props?.[3]?.label),
        Icon: <WSICon />,
        onClick: () =>
          history.push(citizenServicesObj?.props?.[3]?.navigationUrl),
      },
    ],
    styles: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      width: "100%",
    },
  };
  const allInfoAndUpdatesProps = {
    header: t(infoAndUpdatesObj?.headerLabel),
    sideOption: {
      name: t(infoAndUpdatesObj?.sideOption?.name),
      onClick: () => history.push(infoAndUpdatesObj?.sideOption?.navigationUrl),
    },
    options: [
      {
        name: t(infoAndUpdatesObj?.props?.[0]?.label),
        Icon: <HomeIcon />,
        onClick: () =>
          history.push(infoAndUpdatesObj?.props?.[0]?.navigationUrl),
      },
      {
        name: t(infoAndUpdatesObj?.props?.[1]?.label),
        Icon: <Calender />,
        onClick: () =>
          history.push(infoAndUpdatesObj?.props?.[1]?.navigationUrl),
      },
      {
        name: t(infoAndUpdatesObj?.props?.[2]?.label),
        Icon: <DocumentIcon />,
        onClick: () =>
          history.push(infoAndUpdatesObj?.props?.[2]?.navigationUrl),
      },
      {
        name: t(infoAndUpdatesObj?.props?.[3]?.label),
        Icon: <DocumentIcon />,
        onClick: () =>
          history.push(infoAndUpdatesObj?.props?.[3]?.navigationUrl),
      },
      // {
      //     name: t("CS_COMMON_HELP"),
      //     Icon: <HelpIcon/>
      // }
    ],
    styles: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      width: "100%",
    },
  };
  sessionStorage.removeItem("type");
  sessionStorage.removeItem("pincode");
  sessionStorage.removeItem("tenantId");
  sessionStorage.removeItem("localityCode");
  sessionStorage.removeItem("landmark");
  sessionStorage.removeItem("propertyid");

  return isLoading ? (
    <Loader />
  ) : (
    <div className="HomePageContainer">

      <div className="HomePageWrapper" style={{overflowY:'scroll'}}>

        {
          dashboardBarShow &&
          <div className="" style={{position:'relative'}}>
            {isMobile ? <img src={DashBoardBar} /> : <img src={DashBoardBar} />}
            <div style={{position:'absolute', right:15, bottom:35, cursor:'pointer', backgroundColor:'#b6ecfe'}}>
              <X size={30} onClick={handleDashBoardBarClick}/>
            </div>
          </div>
        }

        {/* <img src={MainDashboard} style={{ marginTop: "0px" }} /> */}
        {/* Render iframe only when embedUrl is available */}
        {embedUrl ? (
          <iframe
            src={embedUrl}
            title="AWS QuickSight Dashboard"
            width="100%"
            height="800px"
            style={{ border: 'none', marginTop: '0px' }}
          />
        ) : (
          <p>Loading dashboard...</p>
        )}
      </div>
    </div>
  );
};

export default Home;

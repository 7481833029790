import { Card, KeyNote, SubmitBar } from "@digit-ui-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PTApplication = ({ application, tenantId, buttonLabel }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "590px",
        minHeight: "325px",
        borderRadius: "20px",
        border: "2px solid #D3D3D3",
        padding: "20px",
        display: "flex",
        flexDirection:'column',
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <KeyNote
        keyValue={t("PT_APPLICATION_NO_LABEL")}
        note={application?.acknowldgementNumber}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width:'100%',
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_APPLICATION_CATEGORY")}
            note={t("PROPERTY_TAX")}
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_SEARCHPROPERTY_TABEL_PTUID")}
            note={application?.propertyId}
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_COMMON_TABLE_COL_APP_TYPE")}
            note={
              (application?.creationReason &&
                t(`PT.${application.creationReason}`)) ||
              t("CS_NA")
            }
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_COMMON_TABLE_COL_STATUS_LABEL")}
            note={t(`PT_COMMON_${application?.status}`)}
          />
        </div>
      </div>
      <Link
        to={`/digit-ui/citizen/pt/property/application/${application?.acknowldgementNumber}/${application?.tenantId}`}
      >
        <SubmitBar label={buttonLabel} />
      </Link>
    </div>
  );
};

export default PTApplication;

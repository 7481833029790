import { Card, KeyNote, SubmitBar } from "@digit-ui-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { convertEpochToDate, DownloadReceipt } from "../../../utils/index";

const PTPayments = ({ application }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "590px",
        minHeight: "325px",
        borderRadius: "20px",
        border: "2px solid #D3D3D3",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
      <KeyNote
        noteStyle={{ fontSize: "24px", fontWeight: "700" }}
        keyValue={t("CS_PAYMENT_AMOUNT_PAID_WITHOUT_SYMBOL")}
        note={`₹ ${application?.paymentDetails?.[0]?.totalAmountPaid}`}
      />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_SEARCHPROPERTY_TABEL_PID")}
            note={t(application?.paymentDetails?.[0]?.bill?.consumerCode)}
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_OWNERS_NAME")}
            note={application?.owners?.map((owner) => owner?.name).join(",")}
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_RECEIPT_DATE_LABEL")}
            note={
              convertEpochToDate(
                application?.paymentDetails?.[0]?.receiptDate
              ) || t("CS_NA")
            }
          />
        </div>
        <div
          style={{
            width: "241px",
            height: "93px",
            borderRadius: "12px",
            border: "1px solid #9D9D9D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <KeyNote
            keyValue={t("PT_RECEIPT_NO_LABEL")}
            note={t(application?.paymentDetails?.[0]?.receiptNumber)}
          />
        </div>
      </div>
      <SubmitBar
        label={t("PT_DOWNLOAD_RECEIPT")}
        onSubmit={(e) =>
          DownloadReceipt(
            application?.paymentDetails?.[0]?.bill?.consumerCode,
            application?.tenantId,
            "PT",
            application?.paymentDetails?.[0]?.receiptNumber,
            application
          )
        }
      />
    </div>
  );
};

export default PTPayments;

import React from "react";
import PropTypes from "prop-types";
import { WrapUnMaskComponent } from "..";
const KeyNote = ({ keyValue, note, caption, noteStyle, children, privacy }) => {
  return (
    <div className="">
      <p style={{fontSize:'16px', fontWeight:'400', letterSpacing:'1px', textAlign:'center'}}>{keyValue}</p>
      <div>
      {privacy && <p style={{fontSize:'16px', fontWeight:'700', letterSpacing:'1px', textAlign:'center'}}>
        <WrapUnMaskComponent value={note} iseyevisible={note?.includes("*")?true:false} privacy={privacy}></WrapUnMaskComponent>
        </p>}
      {!privacy && <p style={{fontSize:'16px', fontWeight:'700', letterSpacing:'1px', textAlign:'center'}}>{note}</p>}
      </div>
      <p className="caption">{caption}</p>
      {children}
    </div>
  );
};

KeyNote.propTypes = {
  keyValue: PropTypes.string,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noteStyle: PropTypes.any,
};

KeyNote.defaultProps = {
  keyValue: "",
  note: "",
  noteStyle: {},
};

export default KeyNote;

import React from "react";
import { useTranslation } from "react-i18next";
import { Card, KeyNote, SubmitBar } from "@digit-ui-react-components";
import { Link, useHistory } from "react-router-dom";

// import { getKeyNotesConfig } from "./keynotesConfig";

const MyBill = ({ bill, currentPath, businessService, getKeyNotesConfig }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = () => {
    history.push(`${currentPath}/${bill.consumerCode}`, {
      tenantId: bill?.tenantId,
    });
  };

  return (
    <React.Fragment>
      {typeof getKeyNotesConfig === "function" && (
        <div
          style={{
            width: "590px",
            minHeight: "325px",
            borderRadius: "20px",
            border: "2px solid #D3D3D3",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            {getKeyNotesConfig(businessService, t)["my-bill"].map(
              (obj, index) => {
                const value = obj.keyPath.reduce((acc, key) => {
                  if (typeof key === "function") acc = key(acc);
                  else acc = acc[key];
                  return acc;
                }, bill);
                return (
                  <div
                    style={{
                      width: "241px",
                      height: "93px",
                      borderRadius: "12px",
                      border: "1px solid #9D9D9D",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <KeyNote
                      key={index + obj.keyValue}
                      keyValue={t(obj.keyValue)}
                      note={value || obj.fallback}
                      noteStyle={obj.noteStyle || {}}
                    />
                  </div>
                );
              }
            )}
          </div>
          <SubmitBar
            disabled={!bill.totalAmount?.toFixed(2)}
            onSubmit={onSubmit}
            label={t("CS_MY_APPLICATION_VIEW_DETAILS")}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default MyBill;

import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { Grip } from "lucide-react";

const Popover = ({ content, anchorRef, onClose }) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside both popover and button
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorRef, onClose]);

  if (!anchorRef.current) return null;

  const popoverStyle = {
    position: "absolute",
    top: anchorRef.current.getBoundingClientRect().bottom + 10 + "px",
    left: anchorRef.current.getBoundingClientRect().left - 380 + "px",
    zIndex: 9999,
    width: "380px",
  };

  return createPortal(
    <div ref={popoverRef} className="popover-container" style={popoverStyle}>
      <div  style={{
          backgroundColor: "white",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
          padding: "20px",
        }} onClick={() => onClose()}>{content ? content : "No content available"}</div>
      {/* <button onClick={onClose} style={{ marginTop: "10px" }}>Close</button> */}
    </div>,
    document.body
  );
};

const PopoverButton = ({ element }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const buttonRef = useRef(null);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <div className="popover">
      <Grip
        size={34}
        className={`icon-button${isPopoverOpen ? "-active" : ""}`}
        onClick={togglePopover}
        ref={buttonRef}
      />
      {isPopoverOpen && (
        <Popover
          content={element}
          anchorRef={buttonRef}
          onClose={closePopover}
        />
      )}
    </div>
  );
};

export default PopoverButton;

import {
  BackButton,
  WhatsappIcon,
  Card,
  CitizenHomeCard,
  CitizenInfoLabel,
  PrivateRoute,
} from "@digit-ui-react-components";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundaries";
import { AppHome, processLinkData } from "../../components/Home";
import TopBarSideBar from "../../components/TopBarSideBar";
import StaticCitizenSideBar from "../../components/TopBarSideBar/SideBar/StaticCitizenSideBar";
import CitizenHome from "./Home";
import LanguageSelection from "./Home/LanguageSelection";
import LocationSelection from "./Home/LocationSelection";
import Login from "./Login";
import UserProfile from "./Home/UserProfile";
import ErrorComponent from "../../components/ErrorComponent";
import FAQsSection from "./FAQs/FAQs";
import HowItWorks from "./HowItWorks/howItWorks";
import StaticDynamicCard from "./StaticDynamicComponent/StaticDynamicCard";
import AcknowledgementCF from "../../components/AcknowledgementCF";
import CitizenFeedback from "../../components/CitizenFeedback";
import Search from "./SearchApp";
import QRCode from "./QRCode";
import ChallanQRCode from "./ChallanQRCode";
import bannerImage2 from "../../../../../css/img/upyogi.png";

const sidebarHiddenFor = [
  "digit-ui/citizen/register/name",
  "/digit-ui/citizen/select-language",
  "/digit-ui/citizen/select-location",
  "/digit-ui/citizen/login",
  "/digit-ui/citizen/register/otp",
];
const sidebarShowFor = ["/digit-ui/citizen"];

//TO DO SHANU
// add Dynamically all Liked Data Url

const getTenants = (codes, tenants) => {
  return tenants.filter((tenant) =>
    codes.map((item) => item.code).includes(tenant.code)
  );
};

const Home = ({
  stateInfo,
  userDetails,
  CITIZEN,
  cityDetails,
  mobileView,
  handleUserDropdownSelection,
  logoUrl,
  DSO,
  stateCode,
  modules,
  appTenants,
  sourceUrl,
  pathname,
  initData,
}) => {
  const [finalLinkData, setFinalLinkData] = useState({}); // Initialize state for the final object

  let {
    isLoading: islinkDataLoading,
    data: linkData,
    isFetched: isLinkDataFetched,
  } = Digit.Hooks.useCustomMDMS(
    Digit.ULBService.getStateId(),
    "ACCESSCONTROL-ACTIONS-TEST",
    [
      {
        name: "actions-test",
        filter: "[?(@.url == 'digit-ui-card')]",
      },
    ],
    {
      select: (data) => {
        // const cityModules  =  Digit.MDMSService.getCityModules("mp","indore");
        const formattedData = data?.["ACCESSCONTROL-ACTIONS-TEST"]?.[
          "actions-test"
        ]
          ?.filter((el) => el.enabled === true)
          .reduce((a, b) => {
            a[b.parentModule] =
              a[b.parentModule]?.length > 0 ? [b, ...a[b.parentModule]] : [b];
            return a;
          }, {});

        // const finalObjects={}
        // cityModules.forEach((item)=>{
        //   //  if formattedData has item['code'] in
        //   if(formattedData[item['code']]){
        //     finalObjects[item['code']] = formattedData[item['code']]
        //   }
        // })

        return formattedData;
      },
    }
  );
  const { isLoading, data: citymodules } = Digit.Hooks.obps.useMDMS(
    "pg",
    "tenant",
    ["citymodule"]
  );

  // useEffect((data) => {
  //   console.log(citymodules,"citymodulescitymodulescitymodules")
  //   console.log(citymodules?.tenant?.citymodule?.length,"citymodulescitymodulescitymodules")

  //   if (citymodules?.tenant?.citymodule?.length > 0) {
  //     const finalObjects = {};
  //     citymodules?.tenant?.citymodule?.forEach((item) => {
  //       //  if formattedData has item['code'] in
  //       if (linkData[item["code"]]) {
  //         finalObjects[item["code"]] = linkData[item["code"]];
  //       }
  //     });
  //     console.log(finalObjects,"finalObjects")
  //     // setFinalLinkData(finalObjects);
  //     setFinalLinkData(linkData);
  //   }
  // }, [citymodules, islinkDataLoading, linkData, isLinkDataFetched]);

  const isMobile = window.Digit.Utils.browser.isMobile();
  const classname = Digit.Hooks.fsm.useRouteSubscription(pathname);
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  sourceUrl = "https://s3.ap-south-1.amazonaws.com/egov-qa-assets";
  const pdfUrl =
    "https://pg-egov-assets.s3.ap-south-1.amazonaws.com/Upyog+Code+and+Copyright+License_v1.pdf";
  const history = useHistory();
  const handleClickOnWhatsApp = (obj) => {
    window.open(obj);
  };

  const hideSidebar = sidebarHiddenFor.some((e) =>
    window.location.href.includes(e)
  );
  const showSidebarFor = sidebarShowFor.some(
    (e) => e === window.location.pathname
  );

  const appRoutes = modules.map(({ code, tenants }, index) => {
    const Module = Digit.ComponentRegistryService.getComponent(`${code}Module`);
    return Module ? (
      <Route key={index} path={`${path}/${code.toLowerCase()}`}>
        <Module
          stateCode={stateCode}
          moduleCode={code}
          userType="citizen"
          tenants={getTenants(tenants, appTenants)}
        />
      </Route>
    ) : null;
  });

  const ModuleLevelLinkHomePages = modules.map(
    ({ code, bannerImage }, index) => {
      if (index === 0) {
        let Links =
          Digit.ComponentRegistryService.getComponent(`${code}Links`) ||
          (() => <React.Fragment />);
        let mdmsDataObj = isLinkDataFetched
          ? processLinkData(linkData, code, t)
          : undefined;

        if (mdmsDataObj?.links) {
          mdmsDataObj.links = mdmsDataObj.links.filter(
            (link) =>
              link.name !== "PT_FAQ_S" && link.name !== "PT_HOW_IT_WORKS"
          );

          // Optionally, you can re-sort the remaining links
          mdmsDataObj.links.sort((a, b) => a.orderNumber - b.orderNumber);
        }

        console.log(mdmsDataObj, "mdmsDataObjmdmsDataObj");
        return (
          <React.Fragment>
            {/* <Route key={index} path={`${path}/${code.toLowerCase()}-home`}> */}
            <div className="moduleLinkHomePage">
              {/* <img src={ bannerImage2||bannerImage || stateInfo?.bannerUrl} alt="noimagefound" /> */}
              {/* {isMobile? 
           <h4 style={{top: "calc(16vw + 40px)",left:"1.5rem",position:"absolute",color:"white"}}>{t("MODULE_" + code.toUpperCase())}</h4>:<h1>{t("MODULE_" + code.toUpperCase())}</h1>} */}
              {/* <BackButton className="moduleLinkHomePageBackButton" /> */}
              <div className="">
                {mdmsDataObj && (
                  <>
                    <CitizenHomeCard
                      header={t(mdmsDataObj?.header)}
                      links={mdmsDataObj?.links}
                      Icon={() => <span />}
                      Info={
                        code === "OBPS"
                          ? () => (
                              <CitizenInfoLabel
                                style={{ margin: "0px", padding: "10px" }}
                                info={t("CS_FILE_APPLICATION_INFO_LABEL")}
                                text={t(
                                  `BPA_CITIZEN_HOME_STAKEHOLDER_INCLUDES_INFO_LABEL`
                                )}
                              />
                            )
                          : null
                      }
                      isInfo={code === "OBPS" ? true : false}
                    />
                  </>
                )}
                {/* <Links key={index} matchPath={`/digit-ui/citizen/${code.toLowerCase()}`} userType={"citizen"} /> */}
              </div>

              {/* <StaticDynamicCard moduleCode={code?.toUpperCase()} /> */}
            </div>
            {/* </Route> */}
            <Route
              key={"faq" + index}
              path={`${path}/${code.toLowerCase()}-faq`}
            >
              <FAQsSection module={code?.toUpperCase()} />
            </Route>
            <Route
              key={"hiw" + index}
              path={`${path}/${code.toLowerCase()}-how-it-works`}
            >
              <HowItWorks module={code?.toUpperCase()} />
            </Route>
          </React.Fragment>
        );
      }
    }
  );

  return (
    <div
      className={classname}
      style={{
        height: "100%",
        overflowY: "scroll",
        backgroundColor: "#F1F2F7",
        scrollbarWidth: "none",
        msOverflowStyle: "none"
      }}
    >
      <TopBarSideBar
        t={t}
        stateInfo={stateInfo}
        userDetails={userDetails}
        CITIZEN={CITIZEN}
        cityDetails={cityDetails}
        mobileView={mobileView}
        handleUserDropdownSelection={handleUserDropdownSelection}
        logoUrl={logoUrl}
        showSidebar={true}
        linkData={linkData}
        islinkDataLoading={islinkDataLoading}
      />

      <div
        className={`main center-container citizen-home-container`}
      >
        {!showSidebarFor ? null : (
          <div className="SideBarStatic">
            <StaticCitizenSideBar
              linkData={linkData}
              islinkDataLoading={islinkDataLoading}
            />
          </div>
        )}

        <Switch>
          
          <PrivateRoute exact path={path}>
            <CitizenHome />
          </PrivateRoute>

          <PrivateRoute
            path={`${path}/feedback`}
            component={CitizenFeedback}
          ></PrivateRoute>
          <PrivateRoute
            path={`${path}/feedback-acknowledgement`}
            component={AcknowledgementCF}
          ></PrivateRoute>

          <Route exact path={`${path}/select-language`}>
            <LanguageSelection />
          </Route>

          <Route exact path={`${path}/select-location`}>
            <LocationSelection />
          </Route>

          <Route path={`${path}/error`}>
            <ErrorComponent
              initData={initData}
              goToHome={() => {
                history.push("/digit-ui/citizen");
              }}
            />
          </Route>
          <Route path={`${path}/all-services`}>
            <AppHome
              userType="citizen"
              modules={modules}
              getCitizenMenu={linkData}
              fetchedCitizen={isLinkDataFetched}
              isLoading={islinkDataLoading}
            />
          </Route>

          <Route path={`${path}/login`}>
            <Login stateCode={stateCode} />
          </Route>

          <Route path={`${path}/register`}>
            <Login stateCode={stateCode} isUserRegistered={false} />
          </Route>

          <PrivateRoute path={`${path}/user/profile`}>
            <UserProfile
              stateCode={stateCode}
              userType={"citizen"}
              cityDetails={cityDetails}
            />
          </PrivateRoute>

          <Route path={`${path}/Audit`}>
            <Search />
          </Route>
          <Route path={`${path}/payment/verification`}>
            <QRCode></QRCode>
          </Route>
          {/* <Route path={`${path}/challan/details`}>
            <ChallanQRCode></ChallanQRCode>
          </Route> */}
          <ErrorBoundary initData={initData}>
            <div className="module-container">
              <div
                className="container-links"
                style={{ height: "90vh", overflowY: "scroll",scrollbarWidth: "none",
                msOverflowStyle: "none" }}
              >
                {ModuleLevelLinkHomePages}
              </div>
              <div
                className="container-content"
                style={{ height: "90vh", overflowY: "scroll",scrollbarWidth: "none",
                msOverflowStyle: "none" }}
              >
                {appRoutes}
              </div>
            </div>
          </ErrorBoundary>
        </Switch>
      </div>

      {/* <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "center", color: "black" }}
        >
          <span style={{ cursor: "pointer", fontSize: window.Digit.Utils.browser.isMobile()?"12px":"14px", fontWeight: "400"}} onClick={() => { window.open('https://www.digit.org/', '_blank').focus();}} >Powered by DIGIT</span>
          <span style={{ margin: "0 10px" ,fontSize: window.Digit.Utils.browser.isMobile()?"12px":"14px"}}>|</span>
          <a
            style={{
              cursor: "pointer",
              fontSize: window.Digit.Utils.browser.isMobile() ? "12px" : "14px",
              fontWeight: "400",
            }}
            href="#"
            target="_blank"
          >
            UPYOG License
          </a>

          <span
            className="upyog-copyright-footer"
            style={{
              margin: "0 10px",
              fontSize: window.Digit.Utils.browser.isMobile() ? "12px" : "14px",
            }}
          >
            |
          </span>
          <span
            className="upyog-copyright-footer"
            style={{
              cursor: "pointer",
              fontSize: window.Digit.Utils.browser.isMobile() ? "12px" : "14px",
              fontWeight: "400",
            }}
            onClick={() => {
              window.open("https://niua.in/", "_blank").focus();
            }}
          >
            Copyright © 2022 National Institute of Urban Affairs
          </span>

          <a style={{ cursor: "pointer", fontSize: "16px", fontWeight: "400"}} href="#" target='_blank'>UPYOG License</a>
        </div>
        <div className="upyog-copyright-footer-web">
          <span
            className=""
            style={{
              cursor: "pointer",
              fontSize: window.Digit.Utils.browser.isMobile() ? "12px" : "14px",
              fontWeight: "400",
            }}
            onClick={() => {
              window.open("https://niua.in/", "_blank").focus();
            }}
          >
            Copyright © 2022 National Institute of Urban Affairs
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default Home;

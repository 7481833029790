import React from "react";
import { useTranslation } from "react-i18next";
import { TickMark } from "@digit-ui-react-components";

let actions = [];

const getAction = (flow) => {
  switch (flow) {
    case "STAKEHOLDER":
      actions = [];
      break;
    case "PT_MUTATE":
      actions = [
        "PT_OWNERSHIP_INFO_SUB_HEADER",
        "PT_MUTATION_DETAILS",
        "CE_DOCUMENT_DETAILS",
        "PT_COMMON_SUMMARY",
      ];
      break;
    default:
      actions = [
        "ES_NEW_APPLICATION_PROPERTY_DETAILS",
        "PT_OWNERSHIP_INFO_SUB_HEADER",
        "CE_DOCUMENT_DETAILS",
        "PT_COMMON_SUMMARY",
      ];
  }
};
const Timeline = ({ currentStep = 1, flow = "" }) => {
  const { t } = useTranslation();
  const isMobile = window.Digit.Utils.browser.isMobile();
  getAction(flow);
  return (
    // <div className="timeline-container" style={isMobile ? {} : { maxWidth: "960px", minWidth: "640px", marginRight: "auto", backgroundColor:'red' }}>
    //   {actions.map((action, index, arr) => (
    //     <div className="timeline-checkpoint" key={index}>
    //       <div className="timeline-content">
    //         <span className={`circle ${index <= currentStep - 1 && "active"}`}>{index < currentStep - 1 ? <TickMark /> : index + 1}</span>
    //         <span className="secondary-color">{t(action)}</span>
    //       </div>
    //       {index < arr.length - 1 && <span className={`line ${index < currentStep - 1 && "active"}`}></span>}
    //     </div>
    //   ))}
    // </div>
    <div
      className="timeline-container"
      style={
        isMobile
          ? {}
          : {
              maxWidth: "100%",
              minWidth: "640px",
              marginRight: "auto",
              display: "flex",
              padding: "0",
              position: "relative",
              backgroundColor:'#fff',
              gap: "5px",
            }
      }
    >
      {actions.map((action, index, arr) => (
        <div
          className="timeline-checkpoint"
          key={index}
          style={{
            flex: "1",
            padding: "10px",
            margin: index === 0 ? "0" : "0 0 0 -19px",
            position: "relative",
            background:
              index < currentStep - 1
                ? "repeating-linear-gradient(-65deg, #A9DEFF, #A9DEFF 20px, #A9DEFF 20px, #A9DEFF 40px)"
                : "#fff",
            borderRadius: "0",
            border: "none",
            clipPath:
              index === 0
                ? "polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)"
                : index === arr.length - 1
                ? "polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)"
                : "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)",
                backgroundColor: index <= currentStep - 1 ? "#CAFCBD" : "#F2F2F2",
          }}
        >
          <div
            className="timeline-content"
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            {/* <span
              className={`circle ${index <= currentStep - 1 && "active"}`}
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor:
                  index <= currentStep - 1 ? "#4caf50" : "#e7e7e7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              {index < currentStep - 1 ? <TickMark /> : index + 1}
            </span> */}
            <span
              className="secondary-color"
              style={{
                color: '#000',
                fontSize:'18px',
                fontWeight:'400'
              }}
            >
              {t(action)}
            </span>
          </div>
          {/* {index < arr.length - 1 && (
            <span
              className={`line ${index < currentStep - 1 && "active"}`}
              style={{
                position: "absolute",
                top: "50%",
                right: "-15px",
                width: "30px",
                height: "3px",
                backgroundColor:
                  index < currentStep - 1 ? "#4caf50" : "#e7e7e7",
                transform: "translateY(-50%)",
              }}
            ></span>
          )} */}
        </div>
      ))}
    </div>
  );
};

export default Timeline;

import React from "react";
import { InfoBannerIcon } from "./svgindex";
import { TriangleAlert } from "lucide-react";

const CitizenInfoLabel = ({
  info,
  text,
  style,
  textStyle,
  showInfo = true,
  className,
}) => {
  return (
    <div
      className={`${className ? className : ""}`}
      style={style}
    >
      {showInfo && (
        <>
          <div>
            <TriangleAlert size={24} />
          </div>
          <div>
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "400",
                letterSpacing: "1px",
                textAlign: "left",
                lineHeight: "16px",
                marginBottom: "12px",
              }}
            >
              {info}
            </h2>
            <p style={textStyle} className={"primaryColor"}>
              {text}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CitizenInfoLabel;

import {
  Card,
  CardSubHeader,
  CardText,
  Header,
  Loader,
  SubmitBar,
} from "@digit-ui-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MyProperty from "./my-properties";
import { propertyCardBodyStyle } from "../../../utils";
import { Plus } from "lucide-react";

export const MyProperties = () => {
  const { t } = useTranslation();
  const tenantId =
    Digit.ULBService.getCitizenCurrentTenant(true) ||
    Digit.ULBService.getCurrentTenantId();
  const user = Digit.UserService.getUser().userInfo;

  let filter = window.location.href.split("/").pop();
  let t1;
  let off;
  if (!isNaN(parseInt(filter))) {
    off = filter;
    t1 = parseInt(filter) + 50;
  } else {
    t1 = 4;
  }
  let filter1 = !isNaN(parseInt(filter))
    ? {
        limit: "50",
        sortOrder: "ASC",
        sortBy: "createdTime",
        offset: off,
        tenantId,
        status: "ACTIVE,INACTIVE",
      }
    : {
        limit: "4",
        sortOrder: "ASC",
        sortBy: "createdTime",
        offset: "0",
        mobileNumber: user?.mobileNumber,
        tenantId,
        status: "ACTIVE,INACTIVE",
      };
  const {
    isLoading,
    isError,
    error,
    data,
  } = Digit.Hooks.pt.usePropertySearchNew(
    { filters: filter1, searchedFrom: "myPropertyCitizen" },
    { filters: filter1 }
  );

  if (isLoading) {
    return <Loader />;
  }

  const { Properties: applicationsList } = data || {};

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          padding: "25px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div>
            <Header styles={{ marginBottom: "0px" }}>{`${t(
              "PT_MY_PROPERTIES_HEADER"
            )} ${
              applicationsList ? `(${applicationsList.length})` : ""
            }`}</Header>
          </div>
          <div>
            <Link
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "238px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderRadius: "10px",
                border: "1px solid #003DA5",
                color: "#003DA5",
                fontSize: "16px",
              }}
              to={"/digit-ui/citizen/pt/property/new-application/info"}
            >
              <Plus size={14} />
              {t("CPT_REG_NEW_PROPERTY")}
            </Link>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {applicationsList?.length > 0 &&
                applicationsList.map((application, index) => (
                  <div key={index}>
                    <MyProperty application={application} />
                  </div>
                ))}
            </div>
            {!applicationsList?.length > 0 && (
              <p style={{ marginLeft: "16px", marginTop: "16px" }}>
                {t("PT_NO_PROP_FOUND_MSG")}
              </p>
            )}

            {applicationsList?.length !== 0 && (
              <div style={{ marginTop: "30px" }}>
                <p>
                  <span className="link">
                    {
                      <Link
                        to={`/digit-ui/citizen/pt/property/my-properties/${t1}`}
                      >
                        {t("PT_LOAD_MORE_MSG")}
                      </Link>
                    }
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div style={{backgroundColor:'#FFE1E1', textAlign:'center', height:'62px', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'40px', marginBottom:'10px'}}>
          <p>
            {t("PT_TEXT_NOT_ABLE_TO_FIND_THE_APPLICATION")}{" "}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

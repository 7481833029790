import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import TextArea from "../atoms/TextArea";
import CardLabel from "../atoms/CardLabel";
import CardLabelError from "../atoms/CardLabelError";
import TextInput from "../atoms/TextInput";
import InputCard from "./InputCard";
import { DatePicker } from "@digit-ui-react-components";
const FormStep = ({
  t,
  children,
  config,
  onSelect,
  onSkip,
  value,
  onChange,
  isDisabled,
  _defaultValues = {},
  forcedError,
  componentInFront,
  onAdd,
  cardStyle,
  inputFieldWrapperStyle,
  isMultipleAllow = false,
  showErrorBelowChildren = false,
  childrenAtTheBottom = true,
  textInputStyle,
  submitButtonStyle
}) => {
  const { register, watch, errors, handleSubmit } = useForm({
    defaultValues: _defaultValues,
  });

  const goNext = (data) => {
    onSelect(data);
  };

  var isDisable = isDisabled
    ? true
    : config.canDisable && Object.keys(errors).filter((i) => errors[i]).length;

  const inputs = config.inputs?.map((input, index) => {
    if (input.type === "text") {
      return (
        <React.Fragment key={index}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <CardLabel
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "30px",
                letterSpacing:'.5px',
                marginBottom:'10px'
              }}
            >
              {t(input.label)}
            </CardLabel>
            {errors[input.name] && (
              <CardLabelError>{t(input.error)}</CardLabelError>
            )}
            <div
              className="field-container"
              style={{ justifyContent: "left", width: "342px", height: "36px" , marginBottom:'10px'}}
            >
              {componentInFront ? (
                <span
                  className=""
                  style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor: "#CDFCFF",
                    right: "none",
                    border: "1px solid #D8D8D8",
                    height:"40px",
                    borderRadius: "5px 0px 0px 5px",
                    fontSize: "14px",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                  }}
                >
                  {componentInFront}
                </span>
              ) : null}
              <TextInput
                key={index}
                name={input.name}
                value={value}
                onChange={onChange}
                minlength={input.validation.minlength}
                maxlength={input.validation.maxlength}
                pattern={input.validation?.pattern}
                title={input.validation?.title}
                placeholder={input.placeholder}
                inputRef={register(input.validation)}
                isMandatory={errors[input.name]}
                disable={input.disable ? input.disable : false}
                textInputStyle={textInputStyle}
                style={input.style ? input.style : {
                  backgroundColor: "#fff",
                  borderLeft: "none",
                  border: "1px solid #D8D8D8",
                  borderRadius: "0px 5px 5px 0px",
                  marginBottom:'0px'
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
    if (input.type === "textarea")
      return (
        <React.Fragment key={index}>
          <CardLabel style={{marginBottom:'10px'}}>{t(input.label)}</CardLabel>
          <TextArea
            key={index}
            name={input.name}
            value={value}
            onChange={onChange}
            inputRef={register(input.validation)}
            maxLength="1024"
            style={input.style ? input.style:{}}
          ></TextArea>
        </React.Fragment>
      );
    if (input.type === "date") {
      console.log("datedatedate");
      return (
        <React.Fragment key={index}>
          <CardLabel>
            {t(input.label)} {input.labelChildren && input.labelChildren}
          </CardLabel>
          {errors[input.name] && (
            <CardLabelError>{t(input.error)}</CardLabelError>
          )}
          <div className="field-container" style={{ justifyContent: "left" }}>
            <TextInput
              key={index}
              name={input.name}
              value={value}
              onChange={onChange}
              pattern={input.validation?.pattern}
              title={input.validation?.title}
              inputRef={register(input.validation)}
              isMandatory={errors[input.name]}
              disable={input.disable ? input.disable : false}
              textInputStyle={textInputStyle}
              type={input.type}
            />
          </div>
        </React.Fragment>
      );
    }
  });

  return (
    <form onSubmit={handleSubmit(goNext)}>
      <InputCard
        {...{ isDisable: isDisable, isMultipleAllow: isMultipleAllow }}
        {...config}
        cardStyle={cardStyle}
        submit
        {...{ onSkip: onSkip, onAdd: onAdd }}
        t={t}
        inputFieldWrapperStyle={inputFieldWrapperStyle}
        submitButtonStyle={submitButtonStyle}
      >
        {!childrenAtTheBottom && children}
        {inputs}
        {forcedError && !showErrorBelowChildren && (
          <CardLabelError>{t(forcedError)}</CardLabelError>
        )}
        {childrenAtTheBottom && children}
        {forcedError && showErrorBelowChildren && (
          <CardLabelError>{t(forcedError)}</CardLabelError>
        )}
      </InputCard>
    </form>
  );
};

FormStep.propTypes = {
  config: PropTypes.shape({}),
  onSelect: PropTypes.func,
  onSkip: PropTypes.func,
  onAdd: PropTypes.func,
  t: PropTypes.func,
};

FormStep.defaultProps = {
  config: {},
  onSelect: undefined,
  onSkip: undefined,
  onAdd: undefined,
  t: (value) => value,
};

export default FormStep;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeft, FileText } from "lucide-react";

const CitizenHomeCard = ({
  header,
  links = [],
  state,
  Icon,
  Info,
  isInfo = false,
  styles,
}) => {
  console.log(links, "LINKS");
  const location = useLocation();
  return (
    <div className="CitizenHomeCard">
      {/* <div className="header">
        <h2>{header}</h2>
        <Icon />
      </div> */}
      <div style={{ paddingLeft: "10px" }}>
        <Link
          to="/digit-ui/citizen"
          style={{ color: "#707FDD", display: "flex" }}
        >
          <ArrowLeft />
          &nbsp;&nbsp; Back to Main
        </Link>
        <h5
          style={{
            color: "#959595",
            fontWeight: "700",
            letterSpacing: "1px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          Files Management
        </h5>
      </div>

      <div className="links">
        {links.map((e, i) => {
          // Check if the current link is active based on URL
          const isActive = location.pathname.includes(e.link);

          return (
            <div
              key={e.id}
              className="linksWrapper"
              style={{
                paddingLeft: "0px",
                display: "flex",
                paddingBottom:'0px',
                fontSize: isActive ? "16px" : "15px",
                fontWeight: isActive ? "600" : "400",
                letterSpacing: "0.5px",
                padding: "15px",
                borderLeft: isActive ? "5px solid #5A6ACF" : "none",
                backgroundColor: isActive ? 'rgba(112, 127, 221, 0.1)' : 'transparent',
              }}
            >
              &nbsp;&nbsp;
              {e?.parentModule?.toUpperCase() === "BIRTH" ||
              e?.parentModule?.toUpperCase() === "DEATH" ||
              e?.parentModule?.toUpperCase() === "FIRENOC" ? (
                <a href={e.link} style={{ color: isActive ? "#5A6ACF" : "#3E3E3E" }}>
                  {e.i18nKey}
                </a>
              ) : (
                <Link
                  to={{ pathname: e.link, state: e.state }}
                  style={{ color: isActive ? "#5A6ACF" : "#3E3E3E", paddingBottom:'0px' }} // Active link color
                >
                  {e.i18nKey}
                </Link>
              )}
            </div>
          );
        })}
      </div>
      <div>{isInfo ? <Info /> : null}</div>
    </div>
  );
};

export default CitizenHomeCard;




const  THEME ={
    THEME_DEFAULT : 0,
    THEME_CITY_ONE : 1
} 


const  INPUT ={
   RADIO : 0,
   TEXT : 1
} 








const getTheme = (atom, b, size) => {
   if (atom === INPUT.RADIO) {
     return (b ? 'boundry ' : 'boundry-no ') + (size === 's' ? ' r-size-s ' : size === 'm' ? ' r-size-m ' : ' r-size-l ');
   } else if (atom === INPUT.TEXT) {
     // Handle INPUT.TEXT case here
   }
 };

const  isTheme=()=>{

     if(CURRENT_THEME ==  THEME.THEME_CITY_ONE)
        return true;
     else if(CURRENT_THEME ==  THEME.THEME_DEFAULT)
        return false; 
}

const CURRENT_THEME = THEME.THEME_CITY_ONE;

export {getTheme ,INPUT, THEME}
import {
  CitizenInfoLabel,
  FormStep,
  RadioButtons,
} from "@digit-ui-react-components";
import React, { useState } from "react";
import Timeline from "../components/TLTimeline";

const PropertyBasementsDetails = ({
  t,
  config,
  onSelect,
  userType,
  formData,
}) => {
  const [BasementDetails, setBasementDetails] = useState(
    formData?.noOofBasements
  );

  const menu = [
    {
      //i18nKey: "No Basement",
      code: 0,
      i18nKey: "PT_NO_BASEMENT_OPTION",
    },
    {
      //i18nKey: "1 Basement",
      code: 1,
      i18nKey: "PT_ONE_BASEMENT_OPTION",
    },
    {
      //i18nKey: "2 Basement",
      code: 2,
      i18nKey: "PT_TWO_BASEMENT_OPTION",
    },
  ];

  const onSkip = () => onSelect();

  function selectBasementDetails(value) {
    setBasementDetails(value);
  }

  function goNext() {
    // let index = window.location.href.charAt(window.location.href.length - 1);
    //let index = window.location.href.split("/").pop();
    sessionStorage.setItem("noOofBasements", BasementDetails.i18nKey);
    onSelect(config.key, BasementDetails);
  }

  return (
    <React.Fragment>
      {window.location.href.includes("/citizen") ? (
        <Timeline currentStep={1} />
      ) : null}
      {
        <CitizenInfoLabel
          info={t("CS_FILE_APPLICATION_INFO_LABEL")}
          text={t("PT_BASEMENT_NUMBER_INFO_MSG", BasementDetails)}
          style={{
            height: "73px",
            width: "815px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "24px",
            paddingRight: "24px",
            borderRadius: "10px",
            border: "1px solid #E5D08F",
            backgroundColor: "#FFFEE5",
            marginTop: "24px",
            marginBottom: "24px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: "20px",
          }}
          textStyle={{
            fontSize: "14px",
            letterSpacing: "1px",
            textAlign: "left",
            lineHeight: "16px",
            marginBottom: "0px",
            color: "#FF0000",
          }}
        />
      }
      <FormStep
        t={t}
        config={config}
        onSelect={goNext}
        onSkip={onSkip}
        isDisabled={!BasementDetails}
        isMultipleAllow={true}
        cardStyle={{
          backgroundColor: "#fff",
          boxShadow: "none",
          width: "100%",
          minHeight: "180px",
          borderRadius: "10px",
          border: "1px solid #D3D3D3",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <RadioButtons
          t={t}
          optionsKey="i18nKey"
          isMandatory={config.isMandatory}
          options={menu}
          selectedOption={BasementDetails}
          onSelect={setBasementDetails}
        />
      </FormStep>
    </React.Fragment>
  );
};

export default PropertyBasementsDetails;
